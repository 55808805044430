import * as React from "react"
import { Link, graphql } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"

const BlogIndex = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata?.title || `Title`
  const posts = data.blogPosts.nodes

  return (
    <Layout location={location} title={siteTitle}>
      <Seo title="All posts" />

      <main className="--post-list-page container px-4 max-w-2xl flex-grow">
        <h1 className="--post-list-header text-3xl mb-4">Blog Posts</h1>
        <ol className="--post-list-items-list space-y-2">
          {posts.map(post => {
            const title = post.frontmatter.title || post.fields.slug
            return (
              <li key={post.fields.slug} className="p-2">
                <article
                  className="post-list-item"
                  itemScope
                  itemType="http://schema.org/Article"
                >
                  <header>
                    <h2 className="text-xl font-medium">
                      <Link
                        to={post.fields.slug}
                        itemProp="url"
                        className=" hover:underline"
                      >
                        <span itemProp="headline">{title}</span>
                      </Link>
                    </h2>
                    <small>{post.frontmatter.datetext}</small>
                  </header>
                  <section>
                    <p
                      dangerouslySetInnerHTML={{
                        __html: post.frontmatter.description || post.excerpt,
                      }}
                      className="font-serif text-base"
                      itemProp="description"
                    />
                  </section>
                </article>
              </li>
            )
          })}
        </ol>
      </main>
    </Layout>
  )
}

export default BlogIndex

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }

    blogPosts: allMarkdownRemark(
      filter: { fields: { collection: { eq: "blog" } } }
      sort: { fields: [frontmatter___created], order: DESC }
    ) {
      nodes {
        excerpt
        fields {
          slug
        }
        frontmatter {
          datetext: created(formatString: "LL")
          datetime: created
          title
          description
          tags
        }
      }
    }
  }
`
